@font-face {
  font-family: Calibre;
  src: local(Calibre), url("CalibreRegular.a7383119.otf") format("opentype");
}

@font-face {
  font-family: SF UI;
  src: local(SF UI), url("SFUIText-Regular.394c3074.ttf") format("truetype");
}

@font-face {
  font-family: SF Mono;
  src: local(SF Mono), url("SFMonoRegular.7548ed3b.otf") format("opentype");
}

@font-face {
  font-family: Inter;
  src: local(Inter), url("Inter-Regular.30288fd8.ttf") format("truetype");
}

:root {
  --blue: #6495ed;
  --white: linen;
  --fs-xs: 16px;
  --fs-s: 18px;
  --fs-m: 24px;
  --fs-l: 30px;
  --fs-xl: 80px;
  --fs-heading: 32px;
  --border-radius: 4px;
  --navli-color: #e5d0ff;
  --project-desc-color: #4a434b;
  --navy-shadow: #d45ed8b3;
  --transition: all .25s cubic-bezier(.645, .045, .355, 1);
  --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  --font-inter: "Inter", sans-serif;
  --font-sfui: "SF UI Text", "Helvetica Neue", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif;
  --swiper-pagination-bullet-width: 11px;
  --swiper-pagination-bullet-height: 11px;
  --swiper-pagination-color: #ae55bf;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #fef7ff99;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1.3;
  overflow-x: hidden;
}

body::-webkit-scrollbar-thumb {
  background-color: #dfa3eb;
  border: 3px solid #dfa3eb;
  border-radius: 10px;
}

body::-webkit-scrollbar {
  background-color: #ae55bf4d;
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  width: 100%;
}

html.has-scroll-smooth {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

html.sr .load-hidden {
  visibility: hidden;
}

.main-block {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.main-block.active {
  height: 100vh;
  overflow: hidden;
}

.navbar {
  -webkit-backdrop-filter: blur(7px);
  color: #fff;
  font-family: var(--font-sans);
  background-color: #812e942d;
  border: 1px solid #ffffff05;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 70px;
  padding: 0 50px;
  display: flex;
  position: absolute;
  box-shadow: 0 8px 32px #1f268705;
}

.navbar .link-list {
  transition: all .2s ease-out;
}

@media only screen and (max-width: 900px) {
  .navbar {
    padding: 0 20px;
  }

  .navbar .hamburger {
    flex-direction: column;
    align-items: flex-end;
    display: flex;
  }

  .navbar .link-list {
    display: none;
  }
}

.hamburger {
  cursor: pointer;
  transition: all .2s ease-out;
  display: none;
}

.hamburger .line {
  background-color: #fff;
  border-radius: 4px;
  width: 35px;
  height: 3px;
  margin: 3px 0;
  transition: all .5s;
}

.hamburger .line:nth-child(2) {
  width: 30px;
}

.hamburger .line:nth-child(3) {
  width: 25px;
}

.navbar li {
  margin: 0 15px;
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  .navbar li {
    margin: 0 5px;
  }
}

.navbar li a {
  color: #fff;
  font-family: Calibre, Inter, sans-serif;
  font-size: var(--fs-xs);
  transition: all .2s;
}

.navbar li a:hover {
  color: var(--navli-color);
}

.myname {
  font-size: 20px;
}

.resume-but {
  border-radius: var(--border-radius);
  cursor: pointer;
  background: none;
  border: 1px solid #ffffffb5;
  margin-left: 20px;
  padding: .75rem 1rem;
}

.resume-but a {
  color: #fff !important;
}

.landingpage {
  background: linear-gradient(270deg, #be93c5, #4bc0c8, #4bc881, #c779d0) 0 0 / 800% 800%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.landingpage .ham-links {
  z-index: 900;
  font-size: 1.3rem;
  font-family: var(--font-inter);
  background-color: #fff;
  flex-direction: column;
  justify-content: space-around;
  width: 0;
  height: 100vh;
  padding: 3.5em 0;
  transition: all .5s;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
}

.landingpage .ham-links li {
  max-height: 40px;
  padding: 8px 0;
}

.landingpage .ham-links li a {
  color: #760283;
}

.landingpage .ham-links div, .landingpage .ham-links ul {
  transition: all .3s 3s;
  display: none;
}

.landingpage .say-hii {
  color: #cbc9e2;
  letter-spacing: .25em;
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  max-height: 40px;
  margin-bottom: 20px;
}

.landingpage.active .ham-links {
  width: 100%;
  padding: 3.5em;
}

.landingpage.active .ham-links li {
  width: auto;
}

.landingpage.active .ham-links div, .landingpage.active .ham-links ul {
  display: block;
}

.landingpage.active .ham-links .social-links {
  display: flex;
}

.landingpage.active .ham-links .social-links li {
  margin-right: 32px;
  font-size: 26px;
}

.landingpage.active .hamburger, .landingpage.active .myname {
  z-index: 1000;
  position: relative;
  -webkit-transform: translateZ(0);
}

.landingpage.active .myname {
  font-size: 1.3rem;
  color: #760283 !important;
}

.landingpage.active .hamburger .line {
  background-color: #760283;
}

.landingpage.active .hamburger .line:nth-child(3) {
  display: none;
}

.landingpage.active .hamburger .line:nth-child(2) {
  width: 35px;
  position: absolute;
  top: 0;
  transform: rotate(-45deg);
}

.landingpage.active .hamburger .line:first-child {
  transform: rotate(45deg);
}

.nav-span {
  color: var(--navli-color);
  margin-right: 3px;
}

.landing-page-text {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 50px 150px 0;
  display: flex;
}

.landing-page-text h1 {
  font-size: var(--fs-s);
  font-family: var(--font-mono);
  margin-left: 5px;
}

.landing-heading {
  font-size: var(--fs-xl);
  font-family: var(--font-sans);
  line-height: .9;
}

.main-lan-heading h2 {
  color: #760283;
}

.main-lan-heading {
  margin-top: 25px;
}

.landing-text p {
  font-size: var(--fs-s);
  font-family: var(--font-sans);
  max-width: 579px;
  margin-top: 25px;
}

.sub-lan-heading {
  margin-top: 20px;
}

.handemoji {
  font-size: 30px;
  animation: 3s infinite wiggle;
  display: inline-block;
}

.company-name {
  color: #760283;
  margin: 0 3px;
  font-weight: bold;
}

.final-land-text {
  font-family: var(--font-sans);
  margin-top: 30px;
  display: flex;
}

.final-land-text p {
  font-size: var(--fs-xs);
  max-width: 220px;
  line-height: 1.3;
}

.final-land-text p:nth-child(2) {
  margin-left: 35px;
}

.about-me {
  background-color: #fef7ff99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100vh;
  padding: 35px 150px;
  display: flex;
}

.heading {
  font-family: var(--font-sans);
  color: #ae55bf;
  align-items: center;
  display: flex;
}

.heading p {
  font-size: var(--fs-heading);
  font-weight: bold;
}

.line {
  background-color: #ae55bf;
  width: 350px;
  height: 2px;
  margin-left: 20px;
}

.heading-number {
  letter-spacing: 2.5px;
  margin-right: 4px;
  font-size: 25px;
}

.about-me-cont {
  width: 100%;
  display: flex;
}

.about-me-text {
  font-family: var(--font-sans);
  padding-right: 55px;
}

.about-me-text p {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.3;
}

.about-me-image img {
  transition: var(--transition);
  border-radius: 4px;
  width: 270px;
}

.about-me-image {
  width: 270px;
  height: 270px;
  position: relative;
}

.about-me-image:before {
  content: "";
  z-index: 100;
  transition: var(--transition);
  background-color: #ae55bf38;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.about-me-image:after {
  content: "";
  z-index: -1;
  transition: var(--transition);
  border: 2px solid #ae55bf;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 12px;
  left: 12px;
}

.about-me-cont {
  flex-direction: column;
  max-width: 1358px;
  display: flex;
}

.about-me-text-cont {
  margin-top: 34px;
  display: flex;
}

.about-me-skills {
  color: #760283;
  margin-top: 20px;
  font-size: 16px;
  display: flex;
}

.about-me-skills li {
  line-height: 1.3;
}

.about-me-skills div:nth-child(2) {
  margin-left: 40px;
}

.programming {
  flex-direction: column;
  display: flex;
}

.design-cont {
  flex-direction: column;
  width: 45%;
  display: flex;
}

.skillset {
  width: 47%;
}

.design-cont .skill-img {
  width: 50px;
}

.skills-cont {
  justify-content: space-between;
  margin-top: 40px;
  display: flex;
}

@media screen and (max-width: 900px) {
  .skills-cont .skill-img img {
    width: 44px !important;
    margin-left: 0 !important;
  }

  .skills-cont .skillset h4 {
    font-size: 18px;
  }

  .skills-cont .skillset li {
    font-size: 15px;
  }

  .skills-cont .boots {
    height: 48px;
  }
}

@media screen and (max-width: 807px) {
  .skills-cont {
    flex-direction: column;
    margin-top: 20px;
  }

  .skills-cont .skillset {
    width: 100%;
    margin-top: 30px !important;
  }

  .skills-cont .skillset h4 {
    margin-bottom: 20px;
  }

  .skills-cont .design-cont {
    width: 100%;
  }

  .skills-cont .devs-skills {
    flex-direction: column;
    width: 100%;
    margin-top: 0 !important;
  }

  .skills-cont .devs-skills ul {
    flex-wrap: wrap;
    display: flex;
  }

  .skills-cont .devs-skills ul li {
    margin-right: 6px;
  }

  .skills-cont .devs-skills ul li span {
    margin-right: 0 !important;
  }
}

.skillset h4 {
  font-family: var(--font-mono);
  margin-bottom: 30px;
  font-size: 25px;
  display: flex;
}

.skillset h4 img {
  width: 30px;
  margin-right: 15px;
}

.skill-img {
  flex-wrap: wrap;
  gap: 25px;
  display: flex;
}

@media screen and (max-width: 450px) {
  .skill-img {
    justify-content: space-between;
  }
}

.boots {
  align-self: center;
  height: 56px;
}

.skill-img img {
  filter: grayscale(.5);
  width: 50px;
  transition: all .2s;
}

.skill-img img:hover {
  filter: none;
}

.devs-skills {
  justify-content: space-between;
  margin-top: 45px;
  display: flex;
}

.skillset li {
  line-height: 1.5;
  font-family: var(--font-sans);
  font-size: var(--fs-xs);
}

.skills-intro {
  font-family: var(--font-sans);
  text-align: justify;
  margin-top: 22px;
  font-size: 20px;
}

.project-cont {
  display: flex;
  position: relative;
}

@media screen and (max-width: 750px) {
  .project-cont .project-cont-img-cont, .project-cont .project-cont-desc-cont {
    grid-area: 1 / 1 / span 3 / -1;
  }

  .project-cont .featured-project {
    color: #0c000e;
    margin-bottom: 20px;
  }

  .project-cont .project-cont-desc-cont {
    text-align: left;
    background-color: #dfa3ebad;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    display: flex;
  }

  .project-cont .project-cont-desc-cont h3 {
    margin-bottom: 20px;
  }

  .project-cont .project-tools {
    justify-content: start;
    margin-top: 19px;
  }

  .project-cont .project-tools li {
    color: #0d1017;
  }

  .project-cont .project-tools > :first-child {
    margin-left: 0 !important;
  }

  .project-cont .project-links {
    justify-content: start;
  }

  .project-cont .project-links > :first-child {
    margin-left: 0 !important;
  }

  .project-cont .project-links svg {
    fill: #2d2929 !important;
  }

  .project-cont .project-cont-img-cont img {
    opacity: .3;
    height: 100%;
  }

  .project-cont .project-desc {
    background: none;
    padding: 0;
  }
}

.project-cont {
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(12, 1fr);
  margin-top: 50px;
  display: grid;
}

.project-cont-img-cont {
  grid-area: 1 / 1 / span 3 / 8;
  align-items: center;
  display: flex;
}

.project-cont-img-cont a {
  width: 100%;
  height: 90%;
}

@media screen and (max-width: 750px) {
  .project-cont-img-cont a {
    height: 100%;
  }
}

.project-cont-img-cont img {
  opacity: .7;
  width: 100%;
  height: 100%;
}

.project-cont-desc-cont {
  text-align: right;
  grid-area: 2 / 7 / 3 / -1;
}

.featured-project {
  font-size: 13px;
  font-weight: 600;
  font-family: var(--font-mono);
  color: #a158eb;
}

.project-cont-desc-cont h3 {
  font-size: var(--fs-m);
  font-weight: 600;
  font-family: var(--font-mono);
  margin-bottom: 15px;
}

.project-cont-desc-cont h3 a {
  color: #000;
}

.project-desc {
  font-family: var(--font-inter);
  color: var(--project-desc-color);
  box-shadow: 0 10px 30px -15px var(--navy-shadow);
  background-color: #dfa3eb;
  border-radius: 4px;
  padding: 25px;
  font-size: 15px;
  font-weight: 600;
}

.project-desc p {
  margin: 7px 0;
}

.project-img-link {
  background-color: #463259;
  position: relative;
}

.project-img-link:before {
  content: "";
  z-index: 3;
  transition: var(--transition);
  mix-blend-mode: screen;
  background-color: #201727;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.project-tools, .project-links {
  justify-content: flex-end;
  margin-top: 10px;
  display: flex;
}

.project-links {
  padding-right: 3px;
}

.project-links a {
  margin: 0 0 5px 15px;
}

.project-links svg {
  fill: #a158eb;
  font-size: 18px;
}

.project-links a:first-child svg {
  font-size: 19px;
}

.project-tools li {
  font-family: var(--font-inter);
  color: #000;
  margin: 0 0 5px 20px;
  font-size: 12px;
  font-weight: bold;
}

.project-cont-2 .project-cont-img-cont {
  grid-column: 7 / -1;
}

.project-cont-2 .project-cont-desc-cont {
  text-align: left;
  grid-column: 1 / 8;
}

.project-cont-2 .project-tools, .project-cont-2 .project-links {
  justify-content: flex-start;
}

.project-cont-2 .project-tools li {
  margin: 0 11px 0 0;
}

.project-cont-2 .project-links a {
  margin: 0 15px 5px 0;
}

@media only screen and (max-width: 750px) {
  .project-cont-2 .project-cont-img-cont, .project-cont-2 .project-cont-desc-cont {
    grid-column: 1 / -1 !important;
  }
}

.projects {
  height: auto;
}

.contact-me .contact-form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  min-width: 500px;
  display: flex;
  font-family: var(--font-inter) !important;
}

.contact-me .contact-form div {
  margin: 5px 0;
}

.contact-me .contact-form-2 {
  width: 100%;
  margin: 20px 0 !important;
}

.contact-me .contact-form-1-input {
  width: 45%;
}

.contact-me .contact-form-1 {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.contact-me .contact-form input, .contact-me textarea {
  width: 100%;
  padding: 10px 0;
}

.contact-me .contact-form input::placeholder, .contact-me textarea::placeholder, .contact-me label {
  color: var(--navy-shadow) !important;
}

.contact-me .contact-form input, .contact-me textarea, .contact-me label {
  color: #ae55bf;
  font-size: 15px;
}

.contact-me .contact-form input, .contact-me textarea {
  border: none;
  border-bottom: 1px solid var(--navy-shadow);
  transition: var(--transition);
  background: none;
  outline: none;
}

.contact-me label {
  transition: var(--transition);
  margin-bottom: 15px;
}

.contact-me .contact-form-1-input:focus-within label, .contact-me .contact-form-2:focus-within label {
  color: #ae55bf !important;
}

.contact-me input:focus, .contact-me textarea:focus {
  border-bottom: 1px solid #ae55bf !important;
}

.contact-me .contact-me-heading {
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
  font-family: var(--font-inter) !important;
}

.contact-me .contact-me-heading p {
  text-align: center;
  color: #3d155f;
  width: 60%;
  margin-top: 15px;
  font-size: 1em;
}

.contact-me .contact-me-heading h1 {
  color: #ae55bf;
  font-size: 2.3em;
  font-weight: bold;
}

.contact-me .contact-button {
  font-family: var(--font-sans);
  background: none;
  border: 1px solid #ae55bf;
  min-width: 23em;
  margin-top: 30px;
}

.contact-me .contact-button-cont {
  color: #ae55bf;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin: 0 !important;
}

.contact-me .contact-button-cont svg {
  stroke: #ae55bf;
  margin-left: 10px;
}

.contact-me .contact-button-cont:before {
  content: "";
  pointer-events: none;
  background: #ae55bf;
  width: 101%;
  height: 108%;
  transition: transform .5s cubic-bezier(.23, 1, .32, 1);
  position: absolute;
  inset: -.1em 0 0;
  transform: translateX(-100%);
}

.contact-me .contact-button-cont:hover {
  z-index: 1000;
  color: #fff !important;
}

.contact-me .contact-button-cont:hover svg {
  stroke: #fff !important;
}

.contact-me .contact-button-cont:hover:before {
  z-index: -1;
  transform: translateX(0) !important;
}

@media only screen and (max-width: 700px) {
  .contact-me .contact-form {
    width: 100%;
    min-width: auto;
  }

  .contact-me .contact-me-heading {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .contact-me .contact-form-1 {
    flex-direction: column;
  }

  .contact-me .contact-form-1 .contact-form-1-input {
    width: 100%;
    margin-top: 20px;
  }

  .contact-me .contact-me-heading {
    margin-bottom: 25px;
  }
}

.footer {
  background-color: #26243b;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  padding: 100px 0;
  display: flex;
  position: relative;
}

.footer .footer-cont {
  font-family: var(--font-inter);
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer .footer-cont h3 {
  text-shadow: 0 4px 4px #00000040;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  letter-spacing: 5.3px;
  background-color: red;
  background-image: linear-gradient(90deg, #de6161 2.24%, #2657eb 98.71%);
  background-repeat: repeat;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  width: 30%;
  font-size: 30px;
  font-weight: bold;
}

.footer .footer-cont p {
  color: #a8b2d1;
  font-size: var(--fs-s);
  width: 50%;
  max-width: 500px;
  margin-top: 30px;
  font-size: 16px;
}

.footer .footer-cont .say-hello {
  font-size: var(--fs-s);
  color: #ae55bf;
  transition: var(--transition);
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ae55bf;
  border-radius: 5px;
  margin-top: 40px;
  padding: 15px 27px;
}

.footer .footer-cont .say-hello:hover {
  outline: none;
  transform: translate(-5px, -5px);
  box-shadow: 4px 4px #ae55bf;
}

.footer .bottom-footer-cont p {
  text-align: center;
  color: #a8b2d1;
  font-size: var(--fs-xs);
  font-family: var(--font-inter);
}

.footer .vertical-email {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 45px;
}

.footer .vertical-line {
  background: #a8b2d1;
  width: 2px;
  height: 120px;
}

.footer .vertical-email a {
  writing-mode: vertical-lr;
  color: #a8b2d1;
  font-size: var(--fs-xs);
  font-family: var(--font-inter);
  transition: var(--transition);
  margin-bottom: 15px;
}

.footer .vertical-email a:hover, .footer .vertical-icon-list li:hover {
  color: #ae55bf;
  transform: translateY(-3px);
  fill: #ae55bf !important;
}

.footer .vertical-icon {
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 45px;
}

.footer .vertical-icon-list svg {
  fill: #a8b2d1 !important;
}

.footer .vertical-icon-list li {
  transition: var(--transition);
  fill: #a8b2d1;
  margin: 15px 0;
}

@media only screen and (max-width: 600px) {
  .footer .footer-cont p, .footer .footer-cont h3 {
    width: 65%;
  }

  .footer .vertical-icon {
    left: 20px;
  }

  .footer .vertical-email {
    right: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .about-me {
    height: auto;
    padding: 60px 50px;
  }

  .landingpage {
    height: auto;
  }

  .landing-page-text {
    height: auto;
    padding: 150px 50px;
  }

  .landing-page-text .landing-heading {
    font-size: 50px;
  }

  .footer-cont {
    margin-bottom: 25px;
  }

  .footer {
    height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .about-me, .landing-page-text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about-me-cont .heading {
    justify-content: center;
  }

  .about-me-cont .heading .line {
    display: none;
  }

  .about-me-cont .about-me-text-cont {
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-me-cont .about-me-text-cont .about-me-image {
    width: 200px;
    height: 200px;
  }

  .about-me-cont .about-me-text-cont .about-me-image img {
    width: 200px;
  }

  .about-me-cont .about-me-text-cont .about-me-image img, .about-me-cont .about-me-text-cont .about-me-image:before {
    border-radius: 50%;
  }

  .about-me-cont .about-me-text-cont .about-me-image:hover img, .about-me-cont .about-me-text-cont .about-me-image:hover:before {
    transform: translate(0) !important;
  }

  .about-me-cont .about-me-text-cont .about-me-image:after {
    content: none;
  }

  .about-me-cont .about-me-text-cont .about-me-text {
    margin-top: 30px;
    padding: 0;
  }

  .about-me-cont .about-me-text-cont p {
    text-align: justify;
  }
}

@media only screen and (max-width: 400px) {
  .landingpage.active .ham-links {
    padding: 3.5em 2em;
  }

  .landing-page-text {
    padding: 100px 50px;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }

  80% {
    transform: rotate(0);
  }

  85% {
    transform: rotate(25deg);
  }

  95% {
    transform: rotate(-25deg);
  }

  100% {
    transform: rotate(0);
  }
}

/*# sourceMappingURL=index.13e56b7b.css.map */
